import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Gap from "../elements/gap.tsx";
import Text from "../elements/text.tsx";
import ImageText from "../elements/imagetext.tsx";
import Title from "../elements/title.tsx";
import AliceCarousel from "react-alice-carousel";
import Annotation from "../elements/annotation.tsx";
import TextLayout from "../elements/textlayout.tsx";
import PhotoGallery from "../elements/gallery.tsx";
import "react-alice-carousel/lib/alice-carousel.css";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <TextLayout mdxType="TextLayout">
  <h4>Unbox</h4>
  <h5>
    Unpacking, cocreating, and materialising transdisciplinary collaborative
    processes through physical metaphors
  </h5>
  <br />
  2024 <br />
  Client:{" "}
  <a href="https://www.unusualcollaborations.com/the-power-of-one">
    {" "}
    Center for Unusual Collaboration, the Netherlands
  </a>
  <br />
  Role: Designer
  <br />
  Keywords: boardgame, transdisciplinary collaboration, metaphors
    </TextLayout>
    <PhotoGallery photos={[{
      src: "../../../unbox/ghost.jpg",
      width: 3,
      height: 1
    }, {
      src: "../../../unbox/monster.jpg",
      width: 3,
      height: 1
    }, {
      src: "../../../unbox/full1.jpg",
      width: 3,
      height: 1
    }, {
      src: "../../../unbox/test1.jpeg",
      width: 3,
      height: 1
    }, {
      src: "../../../unbox/test2.jpeg",
      width: 3,
      height: 1
    }, {
      src: "../../../unbox/test7.jpg",
      width: 3,
      height: 1
    }]} mdxType="PhotoGallery" />
    <TextLayout mdxType="TextLayout">
  <b>Introduction</b>
  <Text mdxType="Text">
    A brave team of interdisciplinary scholars combine their expertise to
    address the contemporary challenge of collaborations between practitioners,
    researchers who too often study independently from each other. The team
    plans to develop a gamified 'process black box' prototype to systematise and
    translate their understanding of collaborative research processes into an
    accessible approach that other researchers or participants can benefit from.
    <br />
    <br />
    The approach is to help teams initiate and navigate complex collaborative processes,
    designing something similar to a board game that walks participants through different
    exercises. By using physical objects and metaphors, we promote reflective discussions
    about important aspects of collaboration.
    <img src="../../../unbox/Branding.jpeg" />
    <Annotation mdxType="Annotation">First prototype defines elements of Unbox: a black mysterious
    box, quests, scripts and physical metaphors</Annotation>
    <img src="../../../unbox/storyboard.jpeg" />
    <Annotation mdxType="Annotation">Storyboard</Annotation>
    <img src="../../../unbox/illustration.png" />
     <Annotation mdxType="Annotation">illustration</Annotation>
  </Text>
      <b>Test</b>
      <Text mdxType="Text">
  The play tests were conducted as workshops at RSD13: Rivers of Conversations
  in Oslo, Norway and ITD 24 Conference in Utrecht, the Netherlands.
  <img src="../../../unbox/test1.jpeg" />
  <img src="../../../unbox/test2.jpeg" />
  <img src="../../../unbox/test3.jpeg" />
  <img src="../../../unbox/test4.jpg" />
  <img src="../../../unbox/test7.jpg" />
      </Text>
    </TextLayout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      